<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-layout>
        <a-layout>
          <a-layout-content :style="{ background: '#fff', minHeight: '400px' }">

            <a-row>
              <a-col :span="24">
                <div id="terminal"></div>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <a-input v-model:value="command" placeholder="输入命令后回车" show-count style="width: 100%; height: 100px; font-size: 30px; border-radius: 10px; padding: 20px 10%;" class="req" @pressEnter="sendCommand" />
              </a-col>
            </a-row>

          </a-layout-content>
        </a-layout>

        <a-layout-sider theme="light" width="400" style="margin-left: 1px; padding-left:20px;">
          <a-row :gutter="24" style="margin-left: 10%;margin-right: 10%;">
            <a-col :span="24">
              <a-form-item label="服务器">
                <a-input v-model:value="host" placeholder="43.133.59.15" :allowClear="true" style="width: 100%;" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="端口">
                <a-input v-model:value="port" placeholder="22" :allowClear="true" style="width: 100%;" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="用户">
                <a-input v-model:value="user" placeholder="root" :allowClear="true" style="width: 100%;" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="连接类型">
                <a-select v-model:value="type" style="width: 100%;">
                  <a-select-option value="1">私钥</a-select-option>
                  <a-select-option value="0">密码</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="">
                <span v-if="type==='1'">
                  <a-input id="fileInput" type="file" :allowClear="true" style="width: 100%;" />
                </span>
                  <span v-else>
                  <a-input v-model:value="password" placeholder="******" :allowClear="true" style="width: 100%;" />
                </span>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="" style="text-align: center">
                <a-button type="primary" @click="xtermConnect">连接</a-button>
              </a-form-item>
            </a-col>
          </a-row>

          <span v-if="sessionInfo === ''">未连接</span>
          <a-collapse v-else>
            <span>{{ sessionInfo }}</span><br />
            <span>{{ connectMsg }}</span><br />
          </a-collapse>

        </a-layout-sider>
      </a-layout>

    </a-card>
  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import {message} from "ant-design-vue";
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import "xterm/css/xterm.css"

export default {
  name: 'XtermTerminal',
  components: {
    MainPanel
  },
  data() {
    return {
      host: '43.133.59.15',
      port: '22',
      user: 'root',
      type: '1',
      password: '',

      socket: {},

      sessionInfo: '',
      connectMsg: '',

      command: '',

      myTimer: '',

      terminal: {},


    }
  },

  methods: {
    xtermConnect() {
      // ---formData---
      // const formData = new FormData();
      // formData.append("host", this.host);
      // formData.append("port", this.port);
      // formData.append("user", this.user);
      // formData.append("type", this.type);
      // formData.append("password", this.password);
      // const fileInput = document.querySelector('#fileInput');
      // const file = fileInput.files[0];
      // formData.append("prvkeyFile", file);

      // 建立xterm连接
      let params = this.host + "," + this.port + "," + this.user + "," + this.type + "," + this.password;
      if (this.type === '1') {
        let sshParams = "^_^ | privatekey" + params;
        this.socket.send(sshParams);
        const fileInput = document.querySelector('#fileInput');
        const file = fileInput.files[0];
        if (typeof(file) === 'undefined') {
          message.error("私钥必传");
          return;
        }
        this.socket.send(file);
      } else {
        let pwdParams = "^_^ | password" + params;
        if (this.password === '') {
          message.error("密码必传");
          return;
        }
        this.socket.send(pwdParams);
      }

    },

    sendCommand() {
      if (this.sessionInfo === '') {
        message.error("未连接或已断开");
        return;
      }
      this.socket.send(this.command);
      this.command = '';
    },

    onopen() {
      console.log("---连接已建立---");
      this.myTimer = setInterval(() => {
        // ping
        this.socket.send("");
      }, 50000);
    },

    onmessage(e) {
      // console.log("---接收---", e['data']);
      let receive = e['data'];
      if (receive === '') {
        // pong
        return;
      }
      if (receive.startsWith("^_^ | ws-xterm")) {
        this.sessionInfo = receive;
      } else if (receive.startsWith("^_^ | connect")) {
        this.connectMsg = receive;
        if (receive === "^_^ | connect | xterm连接成功") {
          this.initTerminal();
        }
      } else {
        this.terminal.write(receive);
      }

    },

    onclose() {
      console.log("---连接关闭---");
      this.sessionInfo = '';
      clearInterval(this.myTimer);
      this.initWebSocket();
    },

    initWebSocket() {
      let token = sessionStorage.getItem("token")
      let companyId = sessionStorage.getItem("companyId")
      let roleId = sessionStorage.getItem("roleId")
      this.socket = new WebSocket("wss://dev.julycloud.cn/api/ws-xterm?authorization=" + token + "&companyId=" + companyId + "&roleId=" + roleId);
      this.socket.onopen = this.onopen;
      this.socket.onmessage = this.onmessage;
      this.socket.onclose = this.onclose;
    },

    initTerminal() {
      if (Object.keys(this.terminal).length !== 0) {
        return;
      }
      // 创建终端实例
      this.terminal = new Terminal({
        rows: 40,
        cursorBlink: true,
        theme: {
          background: '#123456',
        },
        fontSize: 16,
      });
      const fitAddon = new FitAddon();

      // 添加fit插件
      this.terminal.loadAddon(fitAddon);

      // 将终端附着到div上
      this.terminal.open(document.getElementById('terminal'));

      // 调整终端大小以适应容器
      fitAddon.fit();
    },


  },

  created() {
    this.initWebSocket();
  },

  mounted() {
  },

}
</script>

<style type="text/css">
  #terminal {
    /* 添加圆角 */
    border-radius: 10px;
    /* 添加阴影 */
    box-shadow: 0px 0px 5px #03a9f4;
    /* 使用文字阴影来增加渐变效果的深度 */
    text-shadow: 0px 0px 10px #03a9f4;
  }

  .req .ant-input {
    /* 字体大小 */
    font-size: 20px;
    /* 内边距 */
    padding: 20px 10%;
    /* 字体颜色 */
    color: blueviolet;
    /* 添加圆角 */
    border-radius: 10px;
    /* 添加阴影 */
    /*box-shadow: 0px 0px 5px #03a9f4;*/
    /* 使用文字阴影来增加渐变效果的深度 */
    /*text-shadow: 0px 0px 10px #03a9f4;*/
    /* 创建线性渐变背景 */
    /*background: linear-gradient(to left, #30CFD0 0%, #330867 100%);*/
    /* 字体类型 */
    /*font-family: 'STXingkai', serif;*/

    background-image: -webkit-linear-gradient(left, #147B96, #E6D205 25%, #147B96 50%, #E6D205 75%, #147B96);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-background-size: 200% 100%;
    -webkit-animation: maskedAnimation 4s infinite linear;

  }

  @keyframes maskedAnimation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

  .xterm .ant-input {
    /* 字体大小 */
    font-size: 20px;
    /* 内边距 */
    padding: 20px 10%;
    /* 字体颜色 */
    color: purple;
    /* 添加圆角 */
    border-radius: 10px;
    /* 添加阴影 */
    /*box-shadow: 0px 0px 5px #03a9f4;*/
    /* 使用文字阴影来增加渐变效果的深度 */
    /*text-shadow: 0px 0px 10px #03a9f4;*/
    /* 创建线性渐变背景 */
    background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
    /* 字体类型 */
    /*font-family: 'STXingkai', serif;*/

    /*background: linear-gradient(90deg, pink, purple);*/
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

  }
</style>

